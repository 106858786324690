import './AboutUsPage.css';
import AboutUs from '../../components/About/AboutUs.js'

function AboutUsPage() {
    return (
        <div className="AboutPage">
            <AboutUs />
        </div>
    );
}

export default AboutUsPage;
