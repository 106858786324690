import './TeamPage.css';
import TeamPage from '../../components/BacklotTeam/teampage'

function BacklotTeam() {
    return (
        <div className="AboutPage">
            <TeamPage />
        </div>
    );
}

export default BacklotTeam;
