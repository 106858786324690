import './AddReviewPage.css';
import AddReview from '../../components/AddReview/addreview'

function AddReviewPage() {
    return (
        <div className="AddReviewPage">
            <AddReview />
        </div>
    );
}

export default AddReviewPage;
