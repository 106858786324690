import './LandingPage.css';
import HomePage from '../../components/Home/homepage'

function LandingPage() {
    return (
        <div className="LandingPage">
            <HomePage />
        </div>
    );
}

export default LandingPage;
