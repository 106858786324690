import './VideosPage.css';
import Videos from '../../components/Videos/Videos'

function VideosPage() {
    return (
        <div className="VideosPage">
            <Videos />
        </div>
    );
}

export default VideosPage;
