import './LoginPage.css';
import Login from '../../components/Login/login'

function LoginPage() {
    return (
        <div className="LoginPage container">
            <Login />
        </div>
    );
}

export default LoginPage;
